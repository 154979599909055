<template>
    <div>      
      <b-modal
          id="modal-form"
          ok-only
          centered
          size="lg"
          content-class="shadow"
          hide-footer
        >
        <template #modal-title> <div style="font-size:24px; font-weight:600;">{{createMode ? 'Tambah Konten' : 'Ubah Konten'}} </div></template>
          <!-- <div class="d-flex justify-content-center align-items-center" style="width:100%;" v-if="createMode">
            <div style="cursor:pointer;background: #FAFAFA;width: 207px;height: 200px;border-radius: 10px;display:flex;flex-flow:column; align-items:center;justify-content:center;">
              <v-icon class="mb-3">mdi-camera-plus-outline</v-icon>
              Tambahkan Foto
            </div>
          </div> -->
          <div class="d-flex justify-content-center align-items-center" style="width:100%;">
            <div style="position:relative;cursor:pointer;width: 207px;height: 200px;border-radius: 10px;display:flex;flex-flow:column; align-items:center;justify-content:center;">
            <img v-if="dataSubmit.path_image !== '' || dataSubmit.path_image !== null"
                    :src="fileURL + '/' + dataSubmit.path_image"
                    style="width: 80%; margin-bottom: 20px; margin-left: 8px;"
                    alt="abstract"
                  />
            <img v-if="dataSubmit.path_image === '' || dataSubmit.path_image === null"
                src="/assets/images/default-thumbnail.jpg"
                class="round-image-dash" style="width: 80%; margin-bottom: 20px; margin-left: 8px;"
              >
                  <b-button :disabled="loadUpload" variant="white" class="shadow" style="padding:0.5rem;position:absolute;bottom:15px;" @click="fileClicked()">
                    <v-icon>mdi-camera-plus-outline</v-icon>
                  </b-button>

                  <input style="display: none;" @change="uploadFile" type="file" :accept="'image/jpg,image/jpeg,image/png'" ref="fileInput"/>
            </div>
          </div>
          <div class="mt-5">
            <b-row class="d-flex align-items-center mb-3">
              <b-col sm="12">
                <p style="font-weight: 600; margin:0px;">Judul Konten</p>
              </b-col>
            </b-row>  
            <b-row class="d-flex align-items-center">
              <b-col sm="12">
                <b-form-input v-model="dataSubmit.title" placeholder="Masukan Judul Konten"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mb-3 mt-4">
              <b-col sm="12">
                <p style="font-weight: 600; margin:0px;">Kategori Konten</p>
              </b-col>
            </b-row>  
            <b-row class="d-flex align-items-center">
              <b-col sm="6">
                <b-form-select type="text" v-model="dataSubmit.m_kategori_konten_id">
                  <!-- <b-form-select-option value="1">testing</b-form-select-option> -->
                  <b-form-select-option :value="item.id" v-for="(item,index) in listKategori" :key="index">{{ item.name}}</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mb-3 mt-4">
              <b-col sm="12">
                <p style="font-weight: 600; margin:0px;">Tanggal Tayang Konten</p>
              </b-col>
            </b-row>  
            <b-row class="d-flex align-items-center">
              <b-col sm="6">
                <b-form-datepicker v-model="dataSubmit.tgl_konten" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mb-3 mt-4">
              <b-col sm="12">
                <p style="font-weight: 600; margin:0px;">Tanggal Tayang Konten Berakhir</p>
              </b-col>
            </b-row>  
            <b-row class="d-flex align-items-center">
              <b-col sm="6">
                <b-form-datepicker v-model="dataSubmit.tgl_tayang" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
              </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mb-3 mt-4">
              <b-col sm="12">
                <p style="font-weight: 600; margin:0px;">Deksripsi</p>
              </b-col>
            </b-row>  
            <b-row class="d-flex align-items-center">
              <b-col sm="12">
                <b-form-textarea
                  id="textarea"
                  v-model="dataSubmit.content"
                  placeholder="Silahkan masukkan Deksripsi"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>

            <div class="text-center">
              <b-button
                variant="outline-primary"
                class="mt-16 text-center mr-5"
                @click="$bvModal.hide('modal-form')"
              >
                Batal
              </b-button>
              <b-button
                variant="primary"
                class="mt-16 text-center"
                @click="submit()"
              >
                {{createMode ? 'Tambah' : 'Ubah'}}
              </b-button>
            </div>
          </div>
      </b-modal>
      <b-modal
        id="formKonfirmasi"
        ok-only
        centered
        size="md"
        content-class="shadow"
        hide-header
        hide-footer
      >
        <div>
          <b-container>
            <p style="font-size:18px; margin-top:20px;">
              Apakah anda yakin ingin Menghapus Konten?
            </p>
          </b-container>
          <div class="text-center">
            <b-button
              variant="outline-secondary"
              class="mt-16 text-center mr-5"
              @click="$bvModal.hide('formKonfirmasi')"
            >
              Tidak
            </b-button>
            <b-button
              variant="danger"
              class="mt-16 text-center"
              @click="deleteData()"
            >
              Yakin
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-modal
          id="detail-konten"
          ok-only
          centered
          size="lg"
          content-class="shadow"
          hide-footer
        >
        <template #modal-title> <div style="font-size:24px; font-weight:600;">Detail Konten</div></template>
        <b-row>
          <b-col cols="12" class="d-flex justify-content-center">
            <img
              src="../../../../assets/image/images/ormas_picture.png"
              style="width: 200px;"
              alt="abstract"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-row class="pb-3 align-items-center">
              <b-col sm="3"
                ><p style="font-weight: 600; margin:0px;">Judul Konten</p></b-col
              >:<b-col sm="8">
                {{ selectedData.title}}
              </b-col>
            </b-row>
            <b-row class="pb-3 align-items-center">
              <b-col sm="3"
                ><p style="font-weight: 600; margin:0px;">Kategori Konten</p></b-col
              >:<b-col sm="8">
                {{ convKategori(selectedData.m_kategori_konten_id)}}
              </b-col>
            </b-row>
            <b-row class="pb-3 align-items-center">
              <b-col sm="3"
                ><p style="font-weight: 600; margin:0px;">Tanggal Konten Tayang</p></b-col
              >:<b-col sm="8">
                {{ selectedData.tgl_konten | moment('DD-MM-YYYY')}}
              </b-col>
            </b-row>
            <b-row class="pb-3 align-items-center">
              <b-col sm="3"
                ><p style="font-weight: 600; margin:0px;">Tanggal Berakhir Tayang</p></b-col
              >:<b-col sm="8">
                {{ selectedData.tgl_tayang | moment('DD-MM-YYYY')}}
              </b-col>
            </b-row>
            <b-row class="pb-3 align-items-center">
              <b-col sm="3"
                ><p style="font-weight: 600; margin:0px;">Status Tayang</p></b-col
              >:<b-col sm="8">
                {{selectedData.status_tayang}}
              </b-col>
            </b-row>
            <b-row class="pb-3">
              <b-col sm="3"
                ><p style="font-weight: 600; margin:0px;">Deskripsi</p></b-col
              >:<b-col sm="8">
                {{ selectedData.content}}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
          <div class="mt-5">
            

            <div class="text-center">
              <b-button
                variant="primary"
                class="mt-16 text-center"
                @click="$bvModal.hide('detail-konten')"
              >
                Tutup
              </b-button>
            </div>
          </div>
      </b-modal>
      
      <b-row class="mt-5">
          <b-col class="d-flex align-items-center justify-content-between">
            <span style="font-size:16px; font-weight:bold;">CMS</span>
            <div>
                <b-button
                size="sm"
                variant="primary"
                @click="createCMS()"
              >
                <v-icon
                  style="color:white;"
                  small
                >mdi-plus-box</v-icon>
                Tambah Konten
              </b-button>
            </div>
          </b-col>
        </b-row>
        <div
            class="table-responsive mt-4"
            style="border-radius:15px;"
          >
            <table
              class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-bordered
          "
              style="font-size:14px !important;"
            >
              <thead>
                <tr class="text-left">
                  <th style="width: 20px" class="text-capitalize">No.</th>
                  <th style="width: 300px" class="text-capitalize">Judul Konten</th>
                  <th style="min-width: 50px" class="text-capitalize">Kategori Konten</th>
                  <th style="min-width: 50px" class="text-capitalize">Tanggal Tayang</th>
                  <th style="min-width: 50px" class="text-capitalize">Berakhir Tayang</th>
                  <th style="min-width: 50px" class="text-capitalize">Status Tayang</th>
                  <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <!-- <tbody v-if="list.length == 0">
                  <template>
                      <tr>
                          <td colspan="6"> -- Tidak ada permohonan -- </td>
                      </tr>
                  </template>
              </tbody> -->
              <tbody style="background: #FFFFFF;">
                <template v-for="(item, i) in listCMS">
                  <tr v-bind:key="i">
                    <td >
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ (i+1) + ((currentPage-1)*perPage)}}</span>
                    </td>
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{item.title}}
                      </span>
                    </td>
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{ convKategori(item.m_kategori_konten_id)}}
                      </span>
                    </td>
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{ item.tgl_konten | moment('DD-MM-YYYY')}}
                      </span>
                    </td>
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{ item.tgl_tayang | moment('DD-MM-YYYY')}}
                      </span>
                    </td>
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{item.status_tayang}}
                      </span>
                    </td>
                    <td>
                      <section>
                        <b-button v-if="item.status_tayang_id != 2" variant="danger" size="sm" style="padding:5px" class="mr-1" @click="confirmDelete(item.id)">
                            <v-icon style="color:white;padding:0px;" small>mdi-trash-can-outline</v-icon>
                          </b-button>
                          <b-button v-if="item.status_tayang_id != 2" variant="primary" size="sm" style="padding:5px" class="mr-1">
                            <v-icon style="color:white;padding:0px;" @click="editKonten(item)" small>mdi-pencil</v-icon>
                          </b-button>
                          <b-button
                            variant="success"
                            style="padding:6px" 
                            size="sm"
                            @click="toDetail(item)"
                          >
                            >>
                          </b-button>
                      </section>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </b-col>
          </b-row>
    </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  name: "Informasi",
  data() {
    return {
      currentPage: 1,
      currentOffset: 0,
      totalRows: 0,
      perPage: 20,
      query: {},
      listCMS: [],
      lisList: false,
      listKontenCMS: [], 
      createMode: true,
      listKategori: [],
      dataSubmit: {
        title: '',
        content: '',
        m_kategori_konten_id: '',
        tgl_konten: '',
        tgl_tayang: '',
        path_image: '',
      },
      idnya: null,
      selectedData: [],
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL,
      loadUpload: false,
    };
  },
  mounted() {
    this.fetchData()
  },
  watch:{
    activeTab(/*to*/){
      
    }
  },
  methods: {
    toDetail(item){
      this.selectedData = item
      this.$bvModal.show('detail-konten')
    },
    convKategori(id){
      var b = this.listKategori.find(el => {
        return el.id == id
      })
      if(b) return b.name
    },
    statTayang(d){
      var dtgl = new Date(d);
      var epoch_d = dtgl.getTime();
      var ntgl = new Date()
      var epoch_n = ntgl.getTime()
      if(epoch_d >= epoch_n){
        return 'Belum Tayang'
      } else {
        return 'Sedang Tayang'
      }
    },
    createCMS(){
      this.createMode = true
      this.dataSubmit = ''
      this.resetData()
      this.$bvModal.show('modal-form')
      
    },
    fetchData(){
      this.lisList = true
      this.listKontenCMS = []
      this.query = {
        offset: this.currentOffset * 20,
        limit: 20,
        skip: 0,
        where: {
          terhapus: false
        }
      };
      this.itemCountGet(this.query)
      this.$store
      .dispatch("getCMS", this.query)
      .then((res) => {
        this.listCMS = res.data
        this.lisList = false
      })
      this.$store
      .dispatch("listKategoriPilihan")
      .then((res) => {
        this.listKategori = res.data
      })
    },
    paginateKategoriCMS() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      this.listCMS = this.listKontenCMS.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    async itemCountGet() {
      // if(q && q.bk || q.ss || q.ck || q.jo || q.sk || q.no){
      this.$store
        .dispatch("getCMSCount")
        .then((res) => {
          this.totalRows = res.data.count
      })
    },
    editKonten(item){
      this.idnya = item.id
      this.createMode = false
      this.dataSubmit = {
        title: item.title,
        content: item.content,
        m_kategori_konten_id: item.m_kategori_konten_id,
        tgl_konten: item.tgl_konten.substring(0, 10),
        tgl_tayang: item.tgl_konten.substring(0, 10),
        path_image: item.path_image
      }
      this.$bvModal.show('modal-form')
    },
    resetData(){
      this.dataSubmit = {
        title: '',
        content: '',
        m_kategori_konten_id: '',
        tgl_konten: '',
        tgl_tayang: ''
      }
    },
    submit(){
      this.dataSubmit.m_kategori_konten_id = parseInt(this.dataSubmit.m_kategori_konten_id)
      let data = this.dataSubmit

      if(this.createMode ){
        this.$store
        .dispatch("submitCMS", data)
        .then(() => {
          Swal.fire({
            title: "Data berhasil disimpan",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          }).then(() => {
            this.$bvModal.hide('modal-form')
            this.dataSubmit = ''
            this.fetchData()
          })
        }).catch(()=>{
            Swal.fire({
            title: "Data gagal disimpan",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          })
        })
      } else{
        this.$store
        .dispatch("submitPatchCMS", [data, this.idnya])
        .then(() => {
          Swal.fire({
            title: "Data berhasil Diubah",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          }).then(() => {
            this.$bvModal.hide('modal-form')
            this.dataSubmit = ''
            this.fetchData()
          })
        }).catch(()=>{
            Swal.fire({
            title: "Data gagal diubah",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          })
        })
      }
      
    },
    deleteData(){
      this.$store
        .dispatch("deleteCMS", this.idnya)
        .then(() => {
          Swal.fire({
            title: "Data berhasil dihapus",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          }).then(() => {
            this.$bvModal.hide('formKonfirmasi')
            this.fetchData()
          })
        })
    },
    confirmDelete(id){
      this.idnya = id
      this.$bvModal.show('formKonfirmasi')
    },
    fileClicked(){
        this.$refs.fileInput.click()  
    },
    uploadFile(e){
      this.loadUpload = true
      var calculateSize = Math.floor(e.target.files[0].size / 1024)
      if(calculateSize > 15000) {
        Swal.fire({
          title: "Gagal Upload",
          text: 'Ukuran File Terlalu Besar',
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.loadUpload = false
        return false
      }
      
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.$store.dispatch('uploadFileGeneral', formData).then((res) =>{

        if(res.data.status != false){
          this.dataSubmit.path_image = res.data.data.newFilePath,
          
              this.loadUpload = false
              Swal.fire({
                title: "Upload File Berhasil",
                text: '',
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              }).then(() => {
                this.$forceUpdate()
              })
        }
        this.$refs.fileInput.value=null;
      }).catch((err) =>{
        this.loadUpload = false
        Swal.fire({
          title: "Upload File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.$refs.fileInput.value=null;
      })
    },
  },
  computed: {
    
  }
};
</script>